<template>
  <v-sheet class="item" id="item" style="height: calc(100vh - 140px)">
    <v-row>
      <v-col md="6" class="my-auto">
        <h1 class="custom-header-blue-text m-0 text-truncate">{{ pageTitle() }}</h1>
      </v-col>
      <v-col md="6" class="text-right">
        <v-btn
          :disabled="pageLoading"
          depressed
          tile
          class="mx-2 white--text"
          color="blue darken-4"
          :to="{
            name: 'item-update',
            params: { id: itemId },
            query: { t: new Date().getTime() },
          }"
        >
          Edit
        </v-btn>
        <v-btn
          :disabled="pageLoading"
          depressed
          tile
          class="mx-2 white--text"
          color="red lighten-1"
          v-on:click="deleteConfirm()"
        >
          Delete
        </v-btn>
        <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
          Back
        </v-btn>
      </v-col>
      <v-col md="12">
        <v-tabs
          v-model="itemTab"
          background-color="transparent"
          color="blue"
          class="custom-tab-transparent"
          active-class="blue darken-4 text-white"
          hide-slider
        >
          <v-tab href="#overview"> Overview </v-tab>
          <v-tab href="#images"> Images </v-tab>
          <v-tabs-items v-model="itemTab">
            <v-tab-item value="overview">
              <v-progress-linear
                indeterminate
                height="6"
                v-if="pageLoading"
                class="position-absolute"
                color="blue darken-4"
              ></v-progress-linear>
              <v-container fluid>
                <table width="100%" class="detail-table mb-10">
                  <tr>
                    <th width="200">Name (In English)</th>
                    <td>
                      <ValueTemplate
                        v-model="item.name_en"
                        title="Name (In English)"
                      ></ValueTemplate>
                    </td>
                    <th width="200">Name (In Chinese)</th>
                    <td>
                      <ValueTemplate
                        v-model="item.name_ch"
                        title="Name (In Chinese)"
                      ></ValueTemplate>
                    </td>
                  </tr>
                  <tr>
                    <th width="200">Price</th>
                    <td>
                      <ValueTemplate v-model="item.amount" currency title="Price"></ValueTemplate>
                    </td>
                    <th width="200">Cuisine</th>
                    <td>
                      <ValueTemplate v-model="item.cuisine" title="Cuisine"></ValueTemplate>
                    </td>
                  </tr>
                  <tr>
                    <th width="200">Sale</th>
                    <td>
                      <template v-if="item.sale"
                        >Yes (
                        <ValueTemplate
                          v-model="item.sale_start"
                          date
                          title="Sale Start"
                        ></ValueTemplate>
                        -
                        <ValueTemplate
                          v-model="item.sale_end"
                          date
                          title="Sale End"
                        ></ValueTemplate>
                        )</template
                      ><template v-else>No</template>
                    </td>
                    <th width="200">Category</th>
                    <td>
                      <ValueTemplate v-model="item.category" title="Category"></ValueTemplate>
                    </td>
                  </tr>
                  <tr>
                    <th width="200">Sale Amount</th>
                    <td>
                      <ValueTemplate
                        v-model="item.sale_amount"
                        currency
                        title="Sale Amount"
                      ></ValueTemplate>
                    </td>
                    <th width="200">Sub Category</th>
                    <td>
                      <ValueTemplate
                        v-model="item.sub_category"
                        title="Sub Category"
                      ></ValueTemplate>
                    </td>
                  </tr>
                  <tr>
                    <th width="200">Reward</th>
                    <td>
                      <template v-if="item.reward"
                        >Yes ({{ item.reward_point }} Point / QTY)</template
                      ><template v-else>No</template>
                    </td>
                    <th width="200">Add On Category</th>
                    <td>
                      <ValueTemplate
                        v-model="item.add_on_category"
                        title="Add On Category"
                      ></ValueTemplate>
                    </td>
                  </tr>
                  <tr>
                    <th>Description</th>
                    <td colspan="3">
                      <ValueTemplate
                        class="break-words"
                        v-model="item.description"
                        title="Description"
                      ></ValueTemplate>
                    </td>
                  </tr>
                </table>
              </v-container>
            </v-tab-item>
            <v-tab-item value="images">
              <v-progress-linear
                indeterminate
                height="6"
                v-if="pageLoading"
                class="position-absolute"
                color="blue darken-4"
              ></v-progress-linear>
              <v-container fluid>
                <v-row class="mx-4">
                  <v-col md="4" class="border-light-grey">
                    <v-chip
                      color="green lighten-1"
                      label
                      outlined
                      class="position-absolute remove-radius bg-white"
                    >
                      Primary
                    </v-chip>
                    <ImageTemplate
                      :src="primaryImage()"
                      custom-class="border-light-grey"
                    ></ImageTemplate>
                  </v-col>
                  <v-col md="8" class="py-0">
                    <v-row>
                      <template v-for="(image, index) in images">
                        <v-col
                          md="2"
                          sm="3"
                          v-if="!image.primary"
                          class="border-light-grey"
                          :key="`image-block-${index}`"
                        >
                          <ImageTemplate :src="image.url"></ImageTemplate>
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
    <DeleteTemplate
      type="item"
      :delete-text="deleteText"
      delete-note="All transactions of this item will also be deleted."
      :delete-dialog="deleteDialog"
      :delete-endpoint="deleteEndpoint"
      v-on:close="deleteDialog = false"
      v-on:delete:success="goBack()"
    ></DeleteTemplate>
  </v-sheet>
</template>

<script>
import { toSafeInteger, find } from "lodash";
import ImageTemplate from "@/view/components/Image";
import ValueTemplate from "@/view/components/ValueTemplate";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ITEM } from "@/core/lib/item.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";

export default {
  name: "item-detail",
  title: "Detail Item",
  data() {
    return {
      pageLoading: true,
      deleteText: null,
      deleteEndpoint: null,
      deleteDialog: false,
      images: [],
      itemId: null,
      item: {
        barcode: null,
        name_en: null,
        name_ch: null,
        reward: null,
        reward_point: null,
        sale: null,
        sale_amount: null,
        sale_start: null,
        sale_end: null,
        description: null,
        cuisine: null,
        category: null,
        sub_category: null,
        add_on_category: null,
        image: [],
        amount: null,
      },
    };
  },
  methods: {
    deleteConfirm() {
      this.deleteText = this.item.barcode;
      this.deleteEndpoint = `item/${this.itemId}`;
      this.deleteDialog = true;
    },
    pageTitle() {
      if (this.item.name_en) {
        return this.item.name_en;
      }
      return "Detail Item";
    },
    primaryImage() {
      const primary = find(this.images, { primary: 1 });
      if (!primary) {
        if (!this.images.length) {
          return null;
        }
        this.images[0].primary = 1;
        return this.images[0].url;
      }
      return primary.url;
    },
    async getItem() {
      try {
        const item = await GET_ITEM(this.itemId);

        this.item = {
          barcode: item.barcode,
          name_en: item.name_en,
          name_ch: item.name_ch,
          reward: item.reward,
          reward_point: item.reward_point,
          sale: item.sale,
          sale_amount: item.sale_amount,
          sale_start: item.sale_start,
          sale_end: item.sale_end,
          description: item.description,
          cuisine: item.cuisine,
          category: item.category,
          sub_category: item.sub_category,
          add_on_category: item.add_on_category,
          image: [],
          amount: item.amount,
        };

        this.images = item.images;

        this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Item", disabled: true },
          { text: "Detail", disabled: true },
          { text: item.barcode, disabled: true },
        ]);
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Item", disabled: true },
      { text: "Detail", disabled: true },
    ]);

    const { id } = this.$route.params;
    if (id) {
      this.itemId = toSafeInteger(id);
      this.getItem();
    } else {
      this.goBack();
    }
  },
  components: {
    DeleteTemplate,
    ImageTemplate,
    ValueTemplate,
  },
  computed: {
    itemTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.itemTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "overview";
      },
    },
  },
};
</script>
